'use client'

import Logo from '@components/Logo/Logo'
import Link from 'next/link'
import { useRef } from 'react'
import { BaseImage } from '../../types/shared'

interface MenuProps {
  children: React.ReactNode
  logo: BaseImage
  menuItems: Partial<{ path: string; title: string }>[]
}

const Menu: React.FC<MenuProps> = ({ children, menuItems, logo }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (): void => {
    inputRef.current?.click()
  }

  return (
    <div className='drawer drawer-end'>
      <input ref={inputRef} id='menu' type='checkbox' className='drawer-toggle' />
      <div className='drawer-content'>
        {children}
        <div className='fixed top-0 p-2 flex justify-between w-full shadow-md shadow-gray-300/20 bg-white bg-opacity-95 dark:bg-zinc-700 z-20 dark:shadow-gray-800/20'>
          <Link href={`/`}>
            <Logo image={logo} />
          </Link>
          <label
            aria-label='open sidebar'
            htmlFor='menu'
            className='drawer-button btn btn-circle btn-outline flex md:hidden'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5' />
            </svg>
          </label>
          <div className='items-center md:flex hidden'>
            {menuItems.map((menu) => (
              <li key={`${menu?.title}-${menu?.path}`} className='list-none mx-2'>
                <Link href={menu.path?.startsWith('/') ? menu.path : `#${menu.path}`}>{menu.title}</Link>
              </li>
            ))}
            <Link
              href={process.env.NEXT_PUBLIC_RESERVATION_LINK ?? 'http://reenio.cz/cs/redirect/reservationpage/18905'}
              target='_blank'
              className='btn bg-black hover:bg-gray-700 text-white dark:bg-white dark:text-black dark:hover:bg-gray-200 ml-3'
            >
              Rezervace
            </Link>
          </div>
        </div>
      </div>

      <div className='drawer-side z-20'>
        <label htmlFor='menu' aria-label='close sidebar' className='drawer-overlay'></label>
        <ul className='menu p-2 w-80 min-h-full bg-white bg-opacity-95 text-black dark:bg-zinc-700 dark:text-white'>
          <li>
            <button
              onClick={handleChange}
              type='button'
              className='btn btn-circle btn-outline self-end'
              aria-label='close menu'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path strokeLinecap='round' strokeLinejoin='round' d='M6 18 18 6M6 6l12 12' />
              </svg>
            </button>
          </li>
          {menuItems.map((menu) => (
            <li key={`${menu?.title}-${menu?.path}`}>
              <Link onClick={handleChange} href={`#${menu.path}`}>
                {menu.title}
              </Link>
            </li>
          ))}
          <Link
            href='http://reenio.cz/cs/redirect/reservationpage/18905'
            target='_blank'
            className='btn bg-black hover:bg-gray-700 text-white mt-3 dark:bg-white dark:text-black'
          >
            Rezervace
          </Link>
        </ul>
      </div>
    </div>
  )
}

export default Menu
