export function transformString(input: string): string {
  // Convert the string to lowercase and replace spaces with dashes
  return input.toLowerCase().replace(/\s+/g, '-')
}

export const isClient = typeof window !== 'undefined'

// Define types for the RichText object
type TextNode = {
  bold?: boolean
  text: string
  type: 'text'
}

export type RichTextParagraph = {
  children: TextNode[]
  type: 'paragraph' | 'list' | 'heading'
}

export type RichTextObject = RichTextParagraph[]

// Function to transform RichText object into HTML string
export function transformToHTML(description: RichTextObject): string {
  if (!description || !Array.isArray(description)) {
    return '' // Return empty string if description is not valid
  }

  // Function to generate HTML for each paragraph object
  const generateParagraphHTML = (paragraph: RichTextParagraph): string => {
    if (!paragraph || !paragraph.children || !Array.isArray(paragraph.children)) {
      return '' // Return empty string if paragraph is not valid
    }

    return (paragraph?.children || [])
      .map((child) => {
        const Element = paragraph.type === 'heading' ? `h2` : 'p'
        return `<${Element} class="${child?.bold ? 'font-bold' : 'font-normal'} my-2">${child?.text}</${Element}>`
      })
      .join('')
  }

  // console.log('**', description)
  // Transform each paragraph object into HTML
  const html = (description || []).map((paragraph) => `${generateParagraphHTML(paragraph)}`).join('')

  return html
}
