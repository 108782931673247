'use client'

import Image from 'next/image'
import { useEffect, useState } from 'react'
import { isClient } from '../../helpers'
import { BaseImage } from '../../types/shared'

const Logo: React.FC<{ image: BaseImage }> = ({ image }) => {
  const [prefersDarkMode, setPrefersDarkMode] = useState<boolean>(false)
  const lightLogo = 'https://res.cloudinary.com/dqqjs2jn4/image/upload/v1728837032/logo_saunaria-light_bwolht.png'

  useEffect(() => {
    if (isClient) {
      const matchDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
      const handler = (e: MediaQueryListEvent) => setPrefersDarkMode(e.matches)
      matchDarkMode.addEventListener('change', handler)
      setPrefersDarkMode(matchDarkMode.matches)

      return () => matchDarkMode.removeEventListener('change', handler)
    }
  }, [])

  return (
    <Image src={prefersDarkMode ? lightLogo : image.url} alt={image.alternativeText ?? ''} height={47} width={53} />
  )
}

export default Logo
